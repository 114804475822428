import React from 'react';
import underConstraction from '../images/under-construction.png';
import './maindiv.css';

function mainDiv() {
    return (
        <div className="mainDiv">
            <div className="Content">
                 <img src={underConstraction} className="underCon" alt="underConstraction"/>
                 <h2 className="header2">Coming soon...</h2>
            </div>
        </div>

    );

}

export default mainDiv;
