import React from 'react';
import Logo from '../images/custom-batteries-logo.png';
import './header.css';

function Header() {
    return (
        <div className="headerSection">
            <a className="" href="https://www.custom-batteries.gr" target="self">
                <img src={Logo} className="logo" alt="logoo"/>
            </a>
        </div>

    );

}

export default Header;
