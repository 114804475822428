
import './App.css';
import Header from './components/header.js';
import Maindiv from './components/maindiv.js';




function App() {
    return (
        <div className="App">
            <Header />
            <Maindiv />
        </div>
);
}

export default App;
